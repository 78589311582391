import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Map } from './components'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import StarIcon from '@material-ui/icons/StarBorder'; // icon example
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'typeface-pathway-gothic-one';
import verifiedIcon from './components/Map/gd-verified-icon.svg';
import unverifiedIcon from './components/Map/gd-unverified-icon.svg';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#008BC1'
    },
    secondary: {
      main: '#F1C900'
    },
    textPrimary: {
      main: '#171C22',
    },
    textSecondary: {
      main: '#fff',
    },
  },
});

const classes = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appResponsive: {
    margin: "20px 10px"
  },
  appBar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flexGrow: 1,
    background: '#fff',
  },
  toolBar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  desktopNavContainer: {
    width: '100%'
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: 240,
    boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    }
  },
  iconButton: {
    float: 'right',
  },
  logoLink: {
    display: 'block',
    width: '170px',
    position: 'relative',
    marginLeft: '14px',
    marginTop: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      marginRight: '27%',
    },
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  logoIcon: {
    height: '28px',
  },
  toolbarLink: {
    margin: '2.8vh auto',
    marginTop: '33px',
    marginBottom: '36px',
    marginLeft: '19px',
    marginRight: '19px',
    fontFamily: 'Pathway Gothic One',
    fontWeight: '700',
    fontSize: '1em',
    letterSpacing: '1.3px',
    '&:hover, &:focus': {
      background: 'transparent',
    },
  },
  toolbarLinkFirst: {
    marginRight: '19px',
    marginLeft: '32px',
  },
  toolbarLinkLast: {
    marginLeft: '5em',
    '&:after': {
      content: '""',
      height: '5px',
      position: 'absolute',
      display: 'block',
      bottom: '-36px',
      width: '100%',
      background: '#DD462F',
    },
  },
  toolbarCTA: {
    padding: '.85em 45px',
    borderRadius: '30px'
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down(900 + theme.spacing.unit * 3 * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    fontFamily: 'Pathway Gothic One',
    background: '#F1C900',
  },
  heroContent: {
    margin: '0 auto',
    marginTop: '100px',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    background: 'url("/gdd-banner-img.jpg")',
    fontFamily: 'Pathway Gothic One',
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
  },
  heroTitle: {
    maxWidth: '960px',
    margin: '0 auto',
    textTransform: 'uppercase',
    fontFamily: 'Pathway Gothic One',
    fontWeight: '700',
    letterSpacing: '1px',
    color: '#fff',
  },
  heroText: {
    maxWidth: '405px',
    margin: '0 auto',
    fontFamily: 'Pathway Gothic One',
    color: '#fff',
  },
  mapSubtext: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 30px ${theme.spacing.unit * 6}px`,
    fontSize: '1.3em',
  },
  keyList: {
    marginLeft: '5em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0'
    },
  },
  keyListItem: {
    paddingTop: '11px',
    paddingBottom: '3px',
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  footer: {
    marginTop: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px`,
    fontFamily: 'Pathway Gothic One',
    background: '#F1F1F1',
  },
  footerHeading: {
    fontFamily: 'Pathway Gothic One',
    fontWeight: '700',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  footerGrid: {
    borderBottom: '1px #AAA solid',
  },
  footerLink: {
    margin: 0,
    fontFamily: 'Helvetica Neue',
    fontWeight: '400',
    textTransform: 'none',
    color: '#171C22',
    textDecoration: 'inherit',
    '&:hover, &:focus, &:active': {
      textDecoration: 'underline',
      background: 'inherit',
    },
    paddingLeft: 0,
    justifyContent: 'left',
  },
  footerCopyright: {
    paddingTop: '50px',
    fontWeight: '400',
    textAlign: 'center',
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  render() {

    const footerLinks = [
      {
        title: 'Home',
        links: [
          {text: 'Mission',               url: "https://globaldignity.org/#mission"}, 
          {text: 'A Global Community',    url: "https://globaldignity.org/#a-global-community"}, 
          {text: 'Global Dignity Day',    url: "https://globaldignity.org/#global-dignity-day"}, 
          {text: 'We All Have a Story',   url: "https://globaldignity.org/#we-all-have-a-story"}, 
          {text: 'Join Us',               url: "https://globaldignity.org/#join-us"}, 
          {text: 'Supporters',            url: "https://globaldignity.org/#supporters"}, 
          {text: 'Partner Organizations', url: "https://globaldignity.org/#partner-organizations"}
        ],
      },
      {
        title: 'Our Story',
        links: [
          {text: 'Mission and Vision',  url: "https://globaldignity.org/our-story/#mission-and-vision"}, 
          {text: 'What We Believe',     url: "https://globaldignity.org/our-story/#what-we-believe"}, 
          {text: 'History',             url: "https://globaldignity.org/our-story/#history"}
        ],
      },
      {
        title: 'Leadership',
        links: [
          {text: 'Founders',                          url: "https://globaldignity.org/leadership/founders/"}, 
          {text: 'Honorary Board',                    url: "https://globaldignity.org/leadership/honorary-board/"}, 
          {text: 'Board',                             url: "https://globaldignity.org/leadership/board-of-directors/"}, 
          {text: 'International Council Of Advisors', url: "https://globaldignity.org/leadership/international-council-of-advisors/"}, 
          {text: 'Staff',                             url: "https://globaldignity.org/leadership/staff/"}
        ],
      },
      {
        title: 'Teaching Dignity',
        links: [
          {text: 'Teaching Tools',            url: "https://globaldignity.org/teaching-dignity/teaching-tools/"}, 
          {text: 'Inspiration',               url: "https://globaldignity.org/teaching-dignity/inspiration/"},
          {text: 'Brand Resources',           url: "https://globaldignity.org/resources/brand-resources/"},
          {text: 'Organizational Resources',  url: "https://globaldignity.org/resources/organizational-resources/"},
          {text: 'Board Resources',           url: "https://globaldignity.org/resources/board-resources/"},
          {text: 'Press',                     url: "https://globaldignity.org/resources/press/"}
        ],
      },
      {
        title: 'Contact',
        links: [
          {text: 'Find your Country',   url: "https://globaldignity.org/contact/#find-your-country"}, 
          {text: 'Write Us',            url: "https://globaldignity.org/contact/#write-us"}, 
          {text: 'Global Home Office',  url: "https://globaldignity.org/contact/#global-home-office"}
        ],
      },
      {
        title: 'Get Involved',
        links: [
          {text: 'Global Dignity Day',  url: "https://globaldignity.org/global-dignity-day/"}, 
          {text: 'Teaching Dignity',    url: "https://globaldignity.org/teaching-dignity/teaching-tools/"}, 
          {text: 'Find Your Country',   url: "https://globaldignity.org/where-we-work/"}, 
          {text: 'Take the Pledge',     url: "https://globaldignity.org/pledge/"}, 
          {text: 'Volunteer',           url: "https://globaldignity.org/volunteer/"}, 
          {text: 'Donate',              url: "https://globaldignity.org/donate/"}
        ],
      },
      {
        title: 'Donate',
        links: [
          {text: 'Just This Once',  url: "https://globaldignity.org/donate/#just-this-once"}, 
          {text: 'Monthly',         url: "https://globaldignity.org/donate/#montly"}
        ],
      },
    ];

    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <a href="https://globaldignity.org" className={classes.logoLink}>
              <Hidden mdDown implementation="css">
                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                  <img src="https://globaldignity.org/wp-content/uploads/2017/12/header-logo.svg" alt="Global Dignity Day" className={classes.logo} />
                </Typography>
              </Hidden>
              <Hidden lgUp implementation="css">
                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                  <img src="https://globaldignity.org/wp-content/uploads/2017/12/header-logo.svg" alt="Global Dignity Day" className={classes.logoIcon} />
                </Typography>
              </Hidden>
            </a>
            <Hidden mdDown implementation="css" className={classes.desktopNavContainer}>
              <Grid container spacing={0}>
                <Grid item xs={8}>
                  <Button href="https://globaldignity.org/our-story/" className={classNames(classes.toolbarLink, classes.toolbarLinkFirst)} disableRipple>Our Story </Button>
                  <Button href="https://globaldignity.org/leadership/" className={classes.toolbarLink} disableRipple>Leadership</Button>
                  <Button href="https://globaldignity.org/teaching-dignity/" className={classes.toolbarLink} disableRipple>Teaching Dignity</Button>
                  <Button href="https://globaldignity.playmc2.com/" className={classes.toolbarLink} disableRipple>Impact Map</Button>
                  <Button href="https://globaldignity.org/contact/" className={classes.toolbarLink} disableRipple>Contact</Button>
                </Grid>
                <Grid item xs={4}>
                  <Button href="https://m.me/globaldignity" className={classNames(classes.toolbarLink, classes.toolbarLinkLast)} disableRipple target="_blank">Submit Action</Button>
                  <Button href="https://globaldignity.org/donate" variant="contained" color="primary" className={classNames(classes.toolbarCTA)}>
                    Donate
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden lgUp implementation="css">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.iconButton}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Toolbar>
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={this.state.mobileOpen}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={this.handleDrawerToggle}
            >
              <div className={classes.appResponsive}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <Button href="https://globaldignity.org/our-story/" className={classes.navLink} disableRipple>Our Story </Button>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Button href="https://globaldignity.org/leadership/" className={classes.navLink} disableRipple>Leadership</Button>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Button href="https://globaldignity.org/teaching-dignity/" className={classes.navLink} disableRipple>Teaching Dignity</Button>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Button href="https://globaldignity.playmc2.com/" className={classes.navLink} disableRipple>Impact Map</Button>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Button href="https://globaldignity.org/contact/" className={classes.navLink} disableRipple>Contact</Button>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Button href="https://m.me/globaldignity" className={classes.navLink} disableRipple target="_blank">Submit Actions</Button>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Button href="https://globaldignity.org/donate" variant="contained" color="primary" className={classNames(classes.toolbarCTA)}>
                      Donate
                    </Button>
                  </ListItem>
                </List>
              </div>
            </Drawer>
          </Hidden>
        </AppBar>
        <main className={classes.layout}>
          {/* Hero unit */}
          <div className={classes.heroContent}>
          <Typography className={classes.heroTitle} component="h3" variant="h3" align="center" color="textSecondary" gutterBottom>
            Global Dignity Day
            
          </Typography>
          <Typography className={classes.heroText} component="h6" variant="h6" align="center" color="textSecondary" gutterBottom>
              This map unites and showcases the acts of kindness people are doing across hashtag campaigns
            </Typography>
            
          </div>
          {/* End hero unit */}
          <Map />
          {/* Map Sub-text; TODO: Better class-name? Style tweaks? */}
          <div className={classes.mapSubtext}>
            <Typography component="p" variant="body" align="center" color="textPrimary">
              If you would like to see your campaign appear hear and celebrate our combined impact, Tweet to <a href="https://twitter.com/@globaldignity" target="_blank">@globaldignity</a> your kindness hashtag and you'll see it here!
            </Typography>
            <List component="nav" className={classes.keyList}>
              <ListItem className={classes.keyListItem}>
                <ListItemIcon>
                  <img alt="v" src={verifiedIcon} height="25px" />
                </ListItemIcon>
                <ListItemText primary="Verified Global Dignity Day Action" />
              </ListItem>
              <ListItem className={classes.keyListItem}>
                <ListItemIcon>
                  <img alt="u" src={unverifiedIcon} height="25px" />
                </ListItemIcon>
                <ListItemText primary="Unverified Global Dignity Day Action" />
              </ListItem>
            </List>
          </div>
          {/* End Map Sub-text */}
        </main>
        {/* Footer */}
        <footer className={classNames(classes.footer, classes.layout)}>
          <Grid container spacing={32} justify="space-evenly" className={classes.footerGrid}>
            {footerLinks.map(footer => (
              <Grid item xs key={footer.title}>
                <Typography className={classes.footerHeading} variant="h6" color="textPrimary" gutterBottom>
                  {footer.title}
                </Typography>
                {footer.links.map(item => (
                  <Typography key={item.text} variant="subtitle1" color="textSecondary">
                    <Button href={item.url} className={classes.footerLink} disableRipple>{item.text}</Button>
                  </Typography>
                ))}
              </Grid>
            ))}
          </Grid>
          <Typography className={classes.footerCopyright} variant="subtitle2" color="textPrimary" gutterBottom>
            Global Dignity c/o BBDO &nbsp; | &nbsp; 1285 Avenue of the Americas, New York New York 10019 &nbsp; | &nbsp; +646.334.9191 &nbsp; | &nbsp; ©2018 Global Dignity
          </Typography>
        </footer>
        {/* End footer */}
      </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(classes)(App);