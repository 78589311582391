import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { string, bool } from 'prop-types';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import './Map.css';

import { InfoWindow, InfoBox } from "react-google-maps";


class MarkerWithState extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isSelected: props.isSelected };
  }

  render() {
    const twitter = this.props.action.twitterMediaURL ? true : false;
    return (
      <Marker
        position = {  this.props.position }
        icon = { this.props.icon }
        onClick = { () => this.setState({isSelected: true }) }   
      >

        { this.state.isSelected && <InfoWindow
          onCloseClick = { () =>  this.setState({isSelected: false }) }
          >
          <div style={{ backgroundColor: `white`, opacity: 0.75, padding: `12px`, width: '300px'}}>
            <div style={{ maxHeight: '250px', fontSize: `16px`, fontColor: `#08233B` }}>
                <Typography variant="h6" color='textPrimary' gutterBottom>{this.props.action.username}</Typography>
                  {twitter ? (
                    <Typography variant="body1" color='textSecondary' gutterBottom>
                      {this.props.action.userDesc.replace(/https:\/\/t.co\/[a-zA-Z0-9]*/g, "")}
                      <a href={this.props.action.twitterMediaURL} target="_blank" rel="noopener noreferrer">{this.props.action.twitterMediaURL}</a>
                    </Typography>
                  ) : (
                    <Typography variant="body1" color='textSecondary' gutterBottom>
                      {this.props.action.userDesc}
                    </Typography>
                  )}
                <img alt="GDD Media" src={this.props.action.mediaUrl} width="285px" />
            </div>
          </div>
        </InfoWindow>}
      </Marker>
    )
  }
}

function randomCloseTo(center, radius) {
  var x0 = center.lng;
  var y0 = center.lat;
  
  var rd = radius/111300;

  var u = Math.random();
  var v = Math.random();

  var w = rd * Math.sqrt(u);
  var t = 2 * Math.PI * v;
  var x = w * Math.cos(t);
  var y = w * Math.sin(t);

  var xp = x/Math.cos(y0);

  return {'lat': y+y0, 'lng': xp+x0};
}

function randomInRange(from, to, fixed) {
  return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  // .toFixed() returns string, so ' * 1' is a trick to convert to number
}

function locatableAction(item) {
  var action = item.val();
  if (action.location.lat === "") { 
    action.location.lat = randomInRange(-45,45,4)
  }
  if (action.location.long === "") { 
    action.location.long = randomInRange(-180,180,4)
  }
  return action;
}

var ottawa = {lat:45.41117, lng:-75.69812};
var canada =  {lat: 50.861979065133895, lng: -90.60921342768597 };

function unplacedParkInOttawa(item) {
  var action = item.val();
  if (action.location.lat === "" || action.location.long === "") { 
    var loc = randomCloseTo(ottawa, 30 * 7000);
    action.location.lat = loc.lat;
    action.location.long = loc.lng;
  }

  return action;
}

const StatelessGoogleMapContainer = withScriptjs(withGoogleMap( () => { 

  return (<GoogleMap
      defaultZoom={2} 
      defaultCenter={ottawa}
  >

    { window.actions.map( (item) => {
        var action = unplacedParkInOttawa(item);
        var markerSize = 28;
        return (
          <MarkerWithState
            action = { action }
            isSelected = { false }
            position={  { lat: action.location.lat , lng: action.location.long } }
            icon = { 
              { 
                scaledSize: {
                  width: markerSize,
                  height: markerSize
                },
                url: action.state === "verified"
                  ? "https://firebasestorage.googleapis.com/v0/b/mc2-gdd-2017.appspot.com/o/map%2Fmarker%2Fgd-verified-icon.svg?alt=media&token=a144c041-3c8d-431d-a7d6-e11c9b0c1553"
                  : "https://firebasestorage.googleapis.com/v0/b/mc2-gdd-2017.appspot.com/o/map%2Fmarker%2Fgd-unverified-icon.svg?alt=media&token=2616954c-6b14-49ff-920e-cf3f542a26ee"
              }
            }
            key={ action.location.lat + action.location.long }
            onClick = { () => 
              {  
                //var url = "https://twitter.com/i/web/status/" + action.tweet.id
                //var bubble = window.open(url, '_blank');
                //bubble.focus(); 
              } 
            }   
          />
        )

    }) }
  </GoogleMap>)
  }
 )
);

export const MapComponent = props => {
    return (
        <div className="Map">
          <StatelessGoogleMapContainer
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCQi4lfoBo4795EMns3Kyjk65P5dXB3m6w&v=3.exp&libraries=geometry,drawing,places"  
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `650px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
    );
  
};

const Map = MapComponent;

export default Map;
